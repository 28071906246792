<template>
  <form @submit.prevent="createContract">
    <modal-card title="Create custom contract">
      <loading v-if="isLoading" />
      <div v-else>
        <b-field label="Product / service">
          <b-select
            v-model="form.productId"
            expanded
            @input="form.productTermId = null"
          >
            <optgroup
              v-for="(group, groupLabel) in productsByGroup"
              :key="`${groupLabel}Group`"
              :label="groupLabel"
            >
              <option v-if="groupLabel === 'Other'" :value="null">
                Custom
              </option>
              <option
                v-for="p in $_(group)
                  .orderBy(['isActive', 'order'], ['desc', 'asc'])
                  .value()"
                :key="p._productId"
                :value="p._productId"
              >
                {{ p.name }} {{ !p.isActive ? "(Discountinued)" : "" }}
              </option>
            </optgroup>
          </b-select>
        </b-field>

        <b-message v-if="sameProvisionContract" type="is-danger">
          <p>
            This site already has an active
            <strong>{{ sameProvisionContract.provision.type }}</strong
            >. It's not possible to have multiple contracts of this type.
          </p>
        </b-message>
        <div v-else>
          <b-field v-if="form.productId" label="Plan *">
            <b-select
              v-model="form.productTermId"
              placeholder="Please select a plan"
              required
              expanded
              @input="productTermChanged"
            >
              <option
                v-for="t in $_(productTerms)
                  .filter(t => t._productId === form.productId)
                  .orderBy(['isActive', 'termPrice.gbp'], ['desc', 'asc'])
                  .value()"
                :key="t._termId"
                :value="t._termId"
              >
                {{ t.name }}
                –
                {{ $formatCurrency(t.termPrice.gbp, "gbp") }}
                {{ !t.isActive ? "(Discountinued)" : "" }}
              </option>
            </b-select>
          </b-field>
          <b-field
            v-if="[null].includes(form.productType)"
            label="Custom name *"
          >
            <b-input
              v-model="form.name"
              type="text"
              placeholder="eg. Custom hosting"
              required
            />
          </b-field>
          <!-- Specifically for domains -->
          <b-field
            v-if="['domain'].includes(form.productType)"
            label="Domain name *"
          >
            <b-input
              v-model="form.name"
              type="text"
              placeholder="eg. domain.com"
              required
            />
          </b-field>
          <b-field label="Date first due">
            <date-time-picker
              readonly
              :min-date="$moment().format('YYYY-MM-DD HH:mm')"
              format="YYYY-MM-DD HH:mm"
              @input="form.dateNextDue = $moment($event).format()"
            />
          </b-field>
          <b-field :label="`Renew every ${form.termLength} month(s) *`">
            <b-input
              v-model.number="form.termLength"
              type="number"
              step="1"
              min="1"
              placeholder="eg. 1"
              required
              :disabled="form.productTermId"
            />
          </b-field>
          <b-field grouped>
            <b-field label="Currency *">
              <b-select v-model="form.currency" expanded>
                <option v-for="c in currencies" :key="c.key" :value="c.key">
                  {{ c.code }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Price *" expanded>
              <b-field>
                <b-input
                  v-model.number="form.termPrice"
                  type="number"
                  step="0.01"
                  min="0"
                  class="is-stretched"
                  placeholder="eg. 39.00"
                />
                <p class="control">
                  <span class="button is-static">{{
                    form.currency.toUpperCase()
                  }}</span>
                </p>
              </b-field>
            </b-field>
          </b-field>
          <b-field
            v-if="
              selectedProductTerm &&
                selectedProductTerm.isDiscountable &&
                selectedProductTerm.priceTierGroup
            "
          >
            <b-switch v-model="form.isDiscountable"
              >Allow discounts (eg. from price tiers, coupons etc)</b-switch
            >
          </b-field>
        </div>
      </div>

      <button
        slot="footer"
        :class="{ 'is-loading': isProcessing }"
        :disabled="isLoading || isProcessing || !isValid"
        type="submit"
        class="button is-success"
      >
        Create
      </button>
    </modal-card>
  </form>
</template>

<script>
const currencies = require("@/data/currencies.json");
import { enums as statuses } from "@/data/contract";
import { collection, doc, getDocs, query, where } from "@firebase/firestore";

export default {
  name: "CreateCustomContract",
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      isProcessing: false,
      products: {},
      productTerms: {},
      contracts: {},
      form: {
        name: "",
        dateNextDue: null,
        currency: "gbp",
        isDiscountable: false,
        productId: null,
        productType: null,
        productTermId: null,
        termPrice: null,
        termLength: 1
      }
    };
  },
  computed: {
    currencies() {
      return this.$_(currencies)
        .map((c, key) => {
          c["key"] = key;
          return c;
        })
        .orderBy(["code"], ["asc"])
        .value();
    },
    isValid() {
      if (!this.form.productTermId && this.$_.isEmpty(this.form.name))
        return false;
      if (!this.$_.isBoolean(this.form.isDiscountable)) return false;
      if (!this.$_.isNumber(this.form.termPrice)) return false;
      if (!this.$_.isNumber(this.form.termLength)) return false;
      if (!this.$_.isEmpty(this.sameProvisionContract)) return false;
      return true;
    },
    sameProvisionContract() {
      const productType = this.$_.get(
        this.products,
        `${this.form.productId}.type`
      );
      return this.$_(this.contracts)
        .filter(i => i.status > statuses.LAPSED)
        .find(
          contract =>
            productType === "subscription" &&
            contract.provision.type === productType
        );
    },
    productsByGroup() {
      return this.$_(this.products)
        .groupBy("group")
        .value();
    },
    selectedProductTerm() {
      return this.$_.get(
        this.productTerms,
        this.$_.get(this.form, "productTermId")
      );
    }
  },
  watch: {
    ["form.productId"](productId) {
      this.$set(
        this.form,
        "productType",
        this.$_.get(this.products, `${productId}.type`, null)
      );
    }
  },
  created() {
    this.getProducts();
    this.getSiteContracts();
  },
  methods: {
    getSiteContracts() {
      const siteRef = doc(this.$firestore, "sites", this.siteId);
      const siteContractsRef = query(
        collection(this.$firestore, "contracts"),
        where("site.ref", "==", siteRef)
      );
      getDocs(siteContractsRef).then(snapshot => {
        if (!snapshot.empty) {
          snapshot.forEach(async contract => {
            this.$set(
              this.contracts,
              contract.id,
              this.$_.merge({}, contract.data(), {
                _id: contract.id
              })
            );
          });
        }
      });
    },
    getProducts() {
      const productsRef = query(
        collection(this.$firestore, "products"),
        where("type", "in", ["subscription", "hosting", "domain", "custom"])
      );
      getDocs(productsRef).then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(async product => {
            await this.getProductTerms(product);
          });
        }
        this.loading = false;
      });
    },
    getProductTerms(product) {
      const termsRef = collection(product.ref, "terms");
      return getDocs(termsRef).then(snapshot => {
        this.isLoading = false;
        if (!snapshot.empty) {
          this.$set(
            this.products,
            product.id,
            this.$_.merge({}, product.data(), {
              _productId: product.id
            })
          );
          snapshot.forEach(term => {
            this.$set(
              this.productTerms,
              term.id,
              this.$_.merge({}, term.data(), {
                _termId: term.id,
                _productId: product.id
              })
            );
          });
        }
      });
    },
    productTermChanged(productTermId) {
      if (productTermId) {
        const productTerm = this.productTerms[productTermId];
        this.$set(this.form, "termLength", productTerm.termLength);
      }
    },
    async createContract() {
      this.isProcessing = true;
      try {
        await this.$store
          .dispatch("billing/createContract", {
            siteId: this.siteId,
            payload: this.form
          })
          .then(response => {
            this.$emit("close");
            this.$toast.open({
              message: response.message
            });
            this.$nextTick(() => {
              this.$router.push({
                path: `/admin/contracts/${response.data.contractId}`
              });
            });
          });
      } catch (error) {
        this.$toast.open({
          message: error,
          type: "is-danger"
        });
      }
      this.isProcessing = false;
    }
  }
};
</script>
